<form (ngSubmit)="submit()" ngNativeValidate>
    <div class="input-container">
        <label for="track-new-comment" class="hidden" trans>New Comment</label>
        <div class="action-container">
            <media-image [media]="currentUser.getModel()"></media-image>
            <input #input type="text" id="track-new-comment" [placeholder]="inReplyTo ? 'Написать ответ':'Написать комментарий'" trans-placeholder [formControl]="commentControl" minlength="4" maxlength="1000" required>
        </div>
    </div>
    <button class="hidden" [disabled]="trackComments.loading$ | async" trans>Submit</button>
</form>
